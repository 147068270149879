.RDSAuthenticator {
  color: red;
  height: 500px;
  width: 30px;
  background-color: peru;
}
.amplify-tabs {
  display: none;
}
fieldset.amplify-flex {
  display: none;
}
.amplify-button--primary {
  display: none;
}
.amplify-button--link{
  display: none;
}
.amplify-divider{
  display: none;
}
