.App {
  text-align: center;
}

body {
  margin: 0;
  display: flex;
  font-weight: 400;
  font-family: 'Noto Sans', sans-serif;
  min-height: min-content !important;
}

html {
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 1280px;
  margin: 0 auto;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.auth-inner {
  width: 192px;
  height: 56px;
  margin: auto;
}

.auth-wrapper .login-title {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.RDSAuthenticator {
  color: red;
  height: 500px;
  width: 30px;
  background-color: peru;
}
