.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #ea2d2d;
  border-color: #1976d2 transparent #1976d2 transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}


.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}



.eye-rolling {
  position: relative;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  box-sizing: border-box;
  background: #fff;
  border: 8px solid #131a1d;
  overflow: hidden;
  box-sizing: border-box;
}
.eye-rolling::after {
  content: '';
  position: absolute;
  left: 0;
  top: -50%;
  width: 100%;
  height: 100%;
  background: #263238 ;
  z-index: 5;
  border-bottom: 8px solid #131a1d;
  box-sizing: border-box;
  animation: eyeShade 3s infinite;
}
.eye-rolling::before {
  content: '';
  position: absolute;
  left: 20px;
  bottom: 15px;
  width: 32px;
  z-index: 2;
  height: 32px;
  background: #111;
  border-radius: 50%;
  animation: eyeMove 3s infinite;
}
@keyframes eyeShade {
  0%   { transform: translateY(0)}
  20%   { transform: translateY(5px)}
  40% , 50%   { transform: translateY(-5px)}
  60%   { transform: translateY( -8px)}
  75%   { transform: translateY( 5px)}
  100%   { transform: translateY(10px)}
}
@keyframes eyeMove {
  0%   { transform: translate(0 , 0)}
  20%   { transform: translate(0px , 5px)}
  40% , 50%   { transform: translate(0px , -5px)}
  60%   { transform: translate(-10px , -5px)}
  75%   { transform: translate(-20px , 5px)}
  100%   { transform: translate(0 , 10px)}
}